.paragraphs-item-image-link {
  display: inline-block;
  text-align: center;

  a {
    color: color('red');
    text-transform: uppercase;
    text-decoration: none;

    &:link,
    &:visited {
      color: color('red');
      text-decoration: none;
    }

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  img {
    display: block;
    max-width: auto;
    width: 100%;
  }

}

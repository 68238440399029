.paragraphs-item-files {
  @include clearfix;

  .field-files {
    padding:10px 0;
    @include clearfix;

    @include respond-to(m) {
      float: left;
      width: 33.33%;
    }

    // clear every third element
    &:nth-child(3n+4) {
      clear: both;
    }
  }

  // icons
  img {
    display: block;
    padding: 10px;
    background: color('blue');
    float: left;
    margin-right: 10px;
    position: relative;
    z-index: 1;
  }

  // link
  a {
    display: block;
    padding: 0 5px 0 57px;
    text-decoration: none;
    font-weight: bold;
    position: relative;
    z-index: 2;

    // download text
    &:after {
      content: "Download";
      display: block;
      font-weight: 100;
      color: color('red');
      margin-top: 10px;
      text-transform: uppercase;
      @include type-layout(s, 1);
    }

    // hover
    &:hover,
    &:active {
      text-decoration: underline;

      &:after {
        text-decoration: underline;
      }
    }
  }
}

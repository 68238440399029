// words
.front {
  .word-banner {
    margin-top: 25px;
    background: color('red');

    .words {
      background: url('../img/footer-words.png') center center no-repeat;
      background-size: contain;
      height: 100px;
      padding-top: 20px;
      padding-bottom: 20px;

      @include respond-to(m) {
        height: 240px;
      }

    }
  }
}

// star
.star-banner {
  background: color('blue') url('../img/star.png') center center no-repeat;
  height: 35px;
}

// mini panel
.layout-3col .panels-flexible-region {
  padding-left: 12px;
  padding-right: 13px;
}
.footer-wrapper {
  .layout-3col__right-sidebar {
    @include respond-to(xl) {
      text-align: right;
    }
  }
}

// social
.social-media-links {
  &.horizontal {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;

      a {
        padding-right: 10px;
        display: block;
      }
    }
  }
}

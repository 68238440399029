// banner
.banner {
  background: url('../img/banner.jpg') top center no-repeat;
  background-size: cover;
  height: 100px;

  @media all and (min-width: 768px) {
    height: 200px;
  }

  // tri banner
  &.tri-banner-content {
    height: auto;
    background: none;
    
    .region-tri-banner {
      max-height: 400px;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: auto;
        padding: 0;
        margin: 0;
      }
      .views-row {
        width: 33.33%;
        float: left;
      }
    }
  }
}

// Dependencies.
@import 'base/headings/headings';
@import 'components/clearfix/clearfix';

// Branding header
//
// Markup: header.twig
//
// Style guide: components.header

// offset header
body {
  padding-top: 75px;

  @media all and (min-width: 928px) {
    padding-top: 124px;
  }
}

.header,
%header {
  @extend %clearfix;
  background: color('blue');
  border-bottom: 5px solid color('yellow');
  box-shadow: 0 5px 5px rgba(black, 0.5);
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media all and (min-width: 928px) {
    border-bottom: 10px solid color('yellow');
  }

  // social
  .block-social-media-links {
    float: right;
    padding-top: 24px;
    padding-left: 25px;

    li {
      height: 32px;
      line-height: 32px;
      padding: 0;

      &.last {
        a {
          padding-right: 0;
        }
      }
      a {
        height: 32px;
        line-height: 32px;
      }
    }
  }

  // links
  a,
  span {
    color: color('white');
  }

  // header region
  .header__region {
    clear: none;

    // remove block margins
    .block {
      margin: 0 0 0 0;
    }

    // secondary menu
    .block-menu {
      float: right;

      ul.menu {
        text-align: left;
        padding: 0 0 0 0;
        li {
          list-style-type: none;
          list-style-image: none;
          display: inline-block;
          padding: 0 10px;
          border-right: 1px solid color('white');
          line-height: 1em;

          &.last {
            border-right: 0;
          }

          a {
            text-decoration: none;
            border-bottom: 2px solid transparent;
            text-transform: uppercase;

            &:hover,
            &:active,
            &.active {
              color: color(white);
              border-bottom: 2px solid color('white');
            }
          }
        }
      }
    }

  }

  // search
  .block-search-api-page {
    float: right;
    padding-left: 20px;

    //icon
    .form-item {
      position: relative;
      z-index: 1;

      &:before {
        display: block;
        content: "";
        height: 30px;
        width: 30px;
        background: url('../img/mag.png') center center no-repeat;
        position: absolute;
        top: 0px;
        left: 0;
      }

    }

    // input
    input[type="text"] {
      width: 200px;
      height: 30px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 10px;
      background: rgba(color('white'), 0.5);
      border-top: 2px solid color(white);
      border-right: 2px solid color(white);
      border-bottom: 2px solid color(white);
      border-left: 30px solid color(white);
    }

    // hide button
    input[type="submit"] {
      @include visually-hidden;
      padding: 0;
      margin: 0;
    }
  }

  // nav-trigger
  .nav-trigger {
    position: relative;
  }

  // navigation region
  .region-navigation {
    clear: right;
    float: right;

    // main menu
    .sf-menu {

      // level 1
      li.sf-depth-1 {
        &.last {
          a {
            padding-right: 0;
          }
        }
      }

      li.active-trail {
        a.sf-depth-1,
        span.sf-depth-1 {
          // triangle
          &:after {
            content: "";
            display: block;
            width: 0px;
            height: 0px;
            position: absolute;
            bottom: 0px;
            left: 50%;
            margin-left: -10px;
            border-bottom: 10px solid color('yellow');
            border-top: 10px solid transparent;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
          }
        }
      }

      a.sf-depth-1,
      span.sf-depth-1 {
        @include type-layout(s, 1.5)
        @include respond-to(container){
          @include type-layout(l, 1.5);
        }
        text-decoration: none;
        text-transform: uppercase;
        padding: 0 10px;

        // triangle
        &:hover,
        &:active,
        &.active {
          color: color('white');
          &:after {
            content: "";
            display: block;
            width: 0px;
            height: 0px;
            position: absolute;
            bottom: 0px;
            left: 50%;
            margin-left: -10px;
            border-bottom: 10px solid color('yellow');
            border-top: 10px solid transparent;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
          }
        }

      }
    }

    // level 2
    li.sf-depth-1 {
      position: static;
    }
    li:hover > ul,
    li.sfHover > ul {
      left: 0;  /* height of header s*/
      top: 124px;
      background: color('red');
      background: rgba(color('red'), 0.8);
      width: 100% !important;
      padding-top: 40px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;

      // make infinite borders
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        width: 9999px;
        height: 100%;
        background: color('red');
        background: rgba(color('red'), 0.8);
      }
      &:before {
        right: -9999px
      }
      &:after {
        left: -9999px
      }

    }
    li.sf-depth-2 {
      width: 33.33%;
      float: left;
      padding: 5px;

      // clear every third
      &:nth-child(3n+4) {
        clear: both;
      }

      a {
        text-transform: uppercase;
        padding: 5px;
        text-decoration: none;
        border-left: 5px solid color('yellow');


        &:link,
        &:visited {
          text-decoration: none;
        }

        // hover amnd active
        &:hover,
        &:active,
        &.active {
          border-left: 5px solid color('yellow');
          background: color('yellow');
          color: color('white');
        }
      }
    }

  }

  // mobile menu trigger
  #sidr-wrapper-0 {
    text-align: right;
    padding-top: 24px;

    #sidr-0-button {
      padding:5px;
      border: 1px solid color('white');
      text-decoration: none;
    }
  }

  // Wrapping link for logo.
  &__logo {
    float: left;
    margin: 0 10px 0 0;
    padding: 0;

    @media all and (min-width: 928px) {
      position: absolute;
      z-index: 500;
      top: 10px;
    }

    @include rtl() {
      float: right;
      margin: 0 0 0 10px;
    }
  }

  // Logo image.
  &__logo-image {
    vertical-align: bottom;
    height: 70px;
    @media all and (min-width: 928px) {
      height: 153px;
    }
  }

  // Wrapper for website name and slogan.
  &__name-and-slogan {
    float: left;
  }

  // The name of the website.
  &__site-name {
    @extend %h1;
    margin: 0;
  }

  // The link around the name of the website.
  &__site-link {
    &:link,
    &:visited {
      color: color(text);
      text-decoration: none;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  // The slogan (or tagline) of a website.
  &__site-slogan {
    margin: 0;
  }

  // The secondary menu (login, etc.)
  &__secondary-menu {
    float: right;

    @include rtl() {
      float: left;
    }
  }

  // Wrapper for any blocks placed in the header region.
  &__region {
    // Clear the logo.
    clear: both;
  }
}

// sidr
.sidr {
  top: 75px !important;
  .sidr-inner {
    padding-bottom: 75px;
  }
  .sidr-class-element-invisible {
    @include visually-hidden;
  }
}

.sidr-class-block-social-media-links {
  ul {
    text-align: center;
  }
  ul li {
    display: inline-block;
    margin: 0;
    line-height: 32px;
    height: 32px;
    border-top: 0;
    border-bottom: 0;
    padding-top: 10px;

    a {
      height: 32px;
    }
  }
}

.front {
  // hide page title
  h1 {
    @include visually-hidden;
  }

  //banner
  .banner {
    margin-bottom: 25px;
  }
}

// default
.main-wrapper {
  margin-bottom: 25px;
}

.default-layout {
  // remove padding from wrapper
  .layout-3col__full {
    // padding: 0 0 0 0;
  }
  // add back padding
  .layout-3col .panels-flexible-region {
    padding-left: 12px;
    padding-right: 13px;
  }
}

// landing page menu
.pane-menu-block-2 {
  ul.menu {
    @include clearfix;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
  }
  li {
    padding: 5px;
    list-style-type: none;
    list-style-image: none;

    @include respond-to(m) {
      float: left;
      width: 33.33%;
      // clear every third
      &:nth-child(3n+4) {
        clear: both;
      }

    }

    a {
      text-transform: uppercase;
      padding: 5px;
      text-decoration: none;
      border-left: 5px solid color('blue');
      display: block;

      &:link,
      &:visited {
        text-decoration: none;
      }

      // hover amnd active
      &:hover,
      &:active,
      &.active {
        border-left: 5px solid color('yellow');
        background: color('yellow');
        color: color('white');
      }
    }

  }
}

// sidebar menu
.pane-menu-block-1 {
  h2.pane-title {
    margin: 0 0 0 0;
    color: color('white');
    text-transform: uppercase;
    background: color('red');
    font-weight: 100;
    padding: 10px;
  }
  .menu-block-wrapper {
    background: color('grey-extra-light');
    overflow: auto;
    padding: 0 10px;
  }
  ul.menu {
    padding: 0 0 0 0;

    li {
      list-style-type: none;
      list-style-image: none;

      a {
        text-transform: uppercase;
        @include type-layout(m, 1);
        text-decoration: none;
        padding-bottom: 10px;
        display: block;

        &:hover,
        &:active {
          text-decoration: underline;
        }

        &.active {
          font-weight: bold;
        }
      }
    }
  }
}

// center
.center-layout {

  // forms
  .resizable-textarea {
    @include respond-to(m) {
      display: inline-block;
      width: auto;
    }
  }
  .webform-client-form {
    max-width: 447px;
    margin: 0 auto;
  }
  .form-actions {
    text-align: center;
  }
  input[type='text'] {
    margin-left: auto;
    margin-right: auto;
  }

  // title
  h1{
    text-align: center;
  }



}

.paragraphs-item-title-and-caption {
  background: color('grey-extra-light');
  @include clearfix;

  .field-title-and-captions {
    padding:10px;
    @include clearfix;

    @include respond-to(m) {
      float: left;
      width: 33.33%;
    }

    .field-title,
    .field-caption {
      display: block;
    }
    
  }
}

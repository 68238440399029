.paragraphs-item-button {
  text-align: center;
  @include clearfix;
  
  .field-links {
    padding: 10px;

    @include respond-to(m){
      width: 50%;
      padding: 10px;
      float: left;
    }
  }
  .group-link-wrapper {
    background: color('yellow');
    color: color('blue');
    display: block;
    padding: 10px;
    text-decoration: none;

    @include respond-to(l) {
      @include type-layout(l, 1.5);
    }

    &:link,
    &:visited {
      text-decoration: none;
    }

    &:hover,
    &:active {
      background: color('blue');
      color: color('white');
    }

    .field-title,
    .field-caption {
      display: block;
    }

  }
}
